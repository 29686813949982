<template>
    <path d="m433.9 97.9-83.9-83.9c-9-9-21.2-14.1-33.9-14.1h-268.1c-26.5 0.1-48 21.6-48 48.1v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-268.1c0-12.7-5.1-25-14.1-34zm-161.9-49.9v80h-128v-80h128zm122 352h-340c-3.3 0-6-2.7-6-6v-340c0-3.3 2.7-6 6-6h42v104c0 13.3 10.7 24 24 24h176c13.3 0 24-10.7 24-24v-100.1l78.2 78.2c1.1 1.1 1.8 2.7 1.8 4.2v259.7c0 3.3-2.7 6-6 6zm-170-200c-48.5 0-88 39.5-88 88s39.5 88 88 88 88-39.5 88-88-39.5-88-88-88zm0 128c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 448, height: 448 });
    },
    emits: ['dimensions'],
};
</script>
